import '../common';

import '../components/columnized.css';
import '../components/dropdown.css';
import '../components/footer';
import '../components/logo';
import '../components/scroll-progress.css';
import '../components/section';
import '../components/anchor.css';

import Documentation from '../components/documentation';
import Header from '../components/header';
import HeaderSearch from '../components/header-search';
import Subscribe from '../components/subscribe';
import PhotoSwipe from '../components/photoswipe';
import Button from '../components/button';
import Tabs from '../components/tabs/tabs';
import DarkMode from '../components/darkMode/darkMode';

document.addEventListener('DOMContentLoaded', () => {
  const getScrollY = () => window.scrollY || document.documentElement.scrollTop;

  const handleScroll = () => {
    document.documentElement.classList.toggle('has-scrolled', getScrollY());

    let scroll = (window.pageYOffset / (document.body.scrollHeight - window.innerHeight)) * 100;
    // ios fix
    if (scroll > 100) {
      scroll = 100;
    }

    document.documentElement.style.setProperty('--scroll-percent', `${scroll}%`);
  };

  /**
   * Tabs
   */
  const tabs = new Tabs();
  tabs.init();

  /**
   * Dark mode switch
   */
  const darkMode = new DarkMode();
  darkMode.init();

  /**
   * Scroll
   */
  handleScroll();
  window.addEventListener('scroll', () => handleScroll());

  /**
   * Header
   */
  const header = new Header();
  header.init();

  /**
   * Search
   */
  if (document.querySelector('.js-header-search')) {
    const search = new HeaderSearch();
    search.init();
  }

  /**
   * Buttons
   */
  const buttons = new Button();
  buttons.init();

  /**
   * Subscribe
   */
  const subscribe = new Subscribe();
  subscribe.init();

  /**
   * PhotoSwipe
   */
  const photoSwipe = new PhotoSwipe();
  photoSwipe.init();

  const documentation = new Documentation();
  documentation.init();
});

window.addEventListener('load', () => {
  document.documentElement.classList.add('is-app-ready');
});
