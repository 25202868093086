import { toPng, toBlob } from 'html-to-image';
import download from "../../utils/download.js";

export class GenerateImageButtonPlugin {
  constructor(options = {}) {
    self.hook = options.hook;
    self.callback = options.callback
  }

  "after:highlightElement"({el}) {
    let button = Object.assign(
      document.createElement("button"),
      {innerHTML: "Image", className: "hljs-button hljs-image-button"}
    );

    el.parentElement.classList.add("hljs-image-wrapper");
    el.parentElement.appendChild(button);

    const buttons = el.parentElement.getElementsByClassName('hljs-button');

    const codeEl = el.parentElement.parentElement.parentElement;

    button.onclick = function () {

      for (let i = 0; i < buttons.length; i++) {
        buttons[i].style.display = "none";
      }

      const margin = codeEl.style.margin

      codeEl.style.margin = "0";

      toBlob(codeEl)
        .then(async function (blob) {
          await navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob
            })
          ])

          button.dataset.copied = true;
          setTimeout(() => {
            button.dataset.copied = false;
          }, 2e3)

          for (let i = 0; i < buttons.length; i++) {
            buttons[i].style.display = "inline";
          }

          codeEl.style.margin = margin
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);

          for (let i = 0; i < buttons.length; i++) {
            buttons[i].style.display = "inline";
          }
          codeEl.style.margin = margin
        })
    }
  }
}
