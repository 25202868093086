import hljs from 'highlight.js/lib';
import {CopyButtonPlugin} from '../../plugins/highlightjs-copy/bundle'
import {GenerateImageButtonPlugin} from '../../plugins/generate-image/bundle'
import golang from 'highlight.js/lib/languages/go';
import protobuf from 'highlight.js/lib/languages/protobuf';
import php from 'highlight.js/lib/languages/php';
import shell from 'highlight.js/lib/languages/shell';
import dockerfile from 'highlight.js/lib/languages/dockerfile';
import bash from 'highlight.js/lib/languages/bash';
import sql from 'highlight.js/lib/languages/sql';
import yaml from 'highlight.js/lib/languages/yaml';
import xml from 'highlight.js/lib/languages/xml';
import diff from 'highlight.js/lib/languages/diff';
import javascript from 'highlight.js/lib/languages/javascript';

import './code.css';
import '../../plugins/highlightjs-copy/bundle.css';
import '../../plugins/generate-image/bundle.css';

import './documentation.css';
import './documentation-content.css';
import './blockquote.css';
import './terminal.css';
import './sections.css';
import './pagination.css';

const scrollToTop = () => window.scrollTo(0, 0);

const toggleContent = (elem, activeElem) => {
  if (activeElem && activeElem.innerText !== elem.innerText) {
    activeElem.classList.remove('active');
    activeElem.setAttribute('aria-expanded', 'false');
    const activeContent = activeElem.nextElementSibling;
    activeContent.style.maxHeight = null;
  }

  elem.classList.toggle('active');
  elem.setAttribute('aria-expanded', elem.classList.contains('active') ? 'true' : 'false');
  const content = elem.nextElementSibling;
  if (content.style.maxHeight) {
    content.style.maxHeight = null;
  } else {
    content.style.maxHeight = `${content.scrollHeight}px`;
  }
};

export default class Documentation {
  constructor() {
    this.elem = document.querySelector('.js-documentation');
    if (!this.elem) return;

    this.navOpenButton = document.querySelector('.js-documentation-nav-toggle');
    this.menu = this.elem.querySelector('.js-documentation-menu');
    this.collapsibleItems = this.elem.querySelectorAll('.js-collapsible');
    this.scrollToTopButton = this.elem.querySelector('.js-documentation-scroll-to-top');

    this.handleToggleListener = this.handleToggle.bind(this);
    this.handleNavOpenListener = this.handleNavOpen.bind(this);
    this.handleNavCloseListener = this.handleNavClose.bind(this);
  }

  bind() {
    this.collapsibleItems.forEach((elem) => elem.addEventListener('click', this.handleToggleListener));
    this.navOpenButton.addEventListener('click', this.handleNavOpenListener);
    this.menu.addEventListener('click', this.handleNavCloseListener);
    this.scrollToTopButton.addEventListener('click', scrollToTop);
  }

  handleToggle(event) {
    const elem = event.target;

    let activeElem = null;
    this.collapsibleItems.forEach((item) => {
      if (item.classList.contains('active')) {
        activeElem = item;
      }
    });

    toggleContent(elem, activeElem);
  }

  handleNavOpen(event) {
    event.preventDefault();
    /* TODO: toggle is-nav-ready on window resize */
    this.elem.classList.add('is-menu-opened', 'is-nav-ready');
    document.body.classList.add('no-scroll');
  }

  handleNavClose(event) {
    if (event.target.classList.contains('js-documentation-menu') && this.elem.classList.contains('is-menu-opened')) {
      this.elem.classList.remove('is-menu-opened');
      document.body.classList.remove('no-scroll');
    }
  }

  init() {
    if (!this.elem) return;

    hljs.addPlugin(new CopyButtonPlugin());
    hljs.addPlugin(new GenerateImageButtonPlugin());

    hljs.registerLanguage('bash', bash);
    hljs.registerLanguage('dotenv', php);
    hljs.registerLanguage('shell', shell);
    hljs.registerLanguage('go', golang);
    hljs.registerLanguage('golang', golang);
    hljs.registerLanguage('php', php);
    hljs.registerLanguage('sql', sql);
    hljs.registerLanguage('yaml', yaml);
    hljs.registerLanguage('xml', xml);
    hljs.registerLanguage('diff', diff);
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('proto', protobuf);
    hljs.registerLanguage('protobuf', protobuf);
    hljs.registerLanguage('docker', dockerfile);

    this.elem.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightElement(block);
    });

    this.collapsibleItems.forEach((elem) => {
      if (elem.getAttribute('aria-expanded') === 'true') {
        toggleContent(elem);
      }
    });

    this.bind();
  }
}
