export class CopyButtonPlugin {
  constructor(options = {}) {
    self.hook = options.hook;
    self.callback = options.callback
  }

  "after:highlightElement"({el, text}) {
    let button = Object.assign(
      document.createElement("button"),
      {className: "hljs-button  hljs-copy-button"}
    );

    button.dataset.copied = false;
    el.parentElement.classList.add("hljs-copy-wrapper");
    el.parentElement.appendChild(button);
    el.parentElement.style.setProperty("--hljs-theme-background", window.getComputedStyle(el).backgroundColor);
    button.onclick = function () {
      if (!navigator.clipboard) return;
      let newText = text;
      if (hook && typeof hook === "function") {
        newText = hook(text, el) || text
      }
      navigator.clipboard.writeText(newText).then(function () {
        button.dataset.copied = true;
        setTimeout(() => {
          button.dataset.copied = false;
        }, 2e3)
      }).then(function () {
        if (typeof callback === "function") return callback(newText, el)
      })
    }
  }
}
