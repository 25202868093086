import JsTabs from 'js-tabs'
import './tabs.css';

export default class Tabs {
  init() {
    const tabs = document.getElementsByClassName("tabs");

    for (let i = 0; i < tabs.length; i++) {
      const myTabs = new JsTabs({
        elm: `#${tabs[i].id}`,
        shouldScrollTabIntoView: false
      })

      myTabs.init()
    }
  }
}
